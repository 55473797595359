/* eslint-disable */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { useAppSelector } from 'hooks/redux';
import { translate } from 'localizations';
import { RootState } from 'store/store';
import { CustomControlSelect } from 'components/common';
import { UniversalButton } from 'shared/ui';

const useStyles = makeStyles({
	searchTitleRight: {
		display: 'flex',
		alignItems: 'center',
	},
	searchDeleteAllButton: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '3px',
		marginRight: '33px',
		cursor: 'pointer',
	},
	searchDeleteAllButtonText: {
		color: '#9254DE !important',
		fontWeight: 500,
		marginLeft: '8px !important',
	},
	searchButton: {
		fontWeight: 500, // @ts-ignore
		textTransform: 'none !important',
		color: '#9254DE !important',
		backgroundColor: '#fff !important',
		'&:hover ': {
			textDecoration: 'underline !important',
			backgroundColor: '#fff !important',
		},
	},
	searchButtonIcon: {
		fill: '#722ED1 !important',
	},
});

const FolderPlusSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.7577 6.67277C17.1038 8.86287 17.079 11.0965 16.6843 13.2782C16.5404 14.0741 15.8813 14.6708 15.0822 14.7285L13.6209 14.8341C10.5605 15.0553 7.48852 15.0553 4.42814 14.8341L3.07734 14.7365C2.21323 14.6741 1.50051 14.0289 1.3448 13.1681C0.963241 11.0588 0.897923 8.9036 1.15102 6.77476L1.39414 4.72991C1.51149 3.74285 2.34055 3 3.32483 3H5.36936C6.25472 3 6.97244 3.72493 6.97244 4.61918C6.97244 4.64872 6.99615 4.67266 7.0254 4.67266H14.799C15.7453 4.67266 16.5513 5.36716 16.7005 6.31101L16.7577 6.67277ZM9.69369 7.7311C9.69369 7.35784 9.39408 7.05523 9.02454 7.05523C8.65498 7.05523 8.35538 7.35784 8.35538 7.7311V9.30813H6.79403C6.42447 9.30813 6.12488 9.61074 6.12488 9.984C6.12488 10.3573 6.42447 10.6599 6.79403 10.6599H8.35538V12.2369C8.35538 12.6102 8.65498 12.9128 9.02454 12.9128C9.39408 12.9128 9.69369 12.6102 9.69369 12.2369V10.6599H11.255C11.6246 10.6599 11.9242 10.3573 11.9242 9.984C11.9242 9.61074 11.6246 9.30813 11.255 9.30813H9.69369V7.7311Z"
			fill="#9254DE"
		/>
	</svg>
);

export const CrossSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.1618 4.83785C11.3896 5.06567 11.3896 5.43503 11.1618 5.66285L5.66187 11.1628C5.43406 11.3906 5.06469 11.3906 4.83688 11.1628C4.60906 10.935 4.60906 10.5656 4.83688 10.3378L10.3368 4.83785C10.5646 4.61004 10.934 4.61004 11.1618 4.83785Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.83688 4.83785C5.06469 4.61004 5.43406 4.61004 5.66187 4.83785L11.1618 10.3378C11.3896 10.5656 11.3896 10.935 11.1618 11.1628C10.934 11.3906 10.5646 11.3906 10.3368 11.1628L4.83688 5.66285C4.60906 5.43503 4.60906 5.06567 4.83688 4.83785Z"
			fill="black"
		/>
	</svg>
);

const Refresh = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8.80926 8L12.9108 3.11094C12.9796 3.02969 12.9218 2.90625 12.8155 2.90625H11.5686C11.4952 2.90625 11.4249 2.93906 11.3764 2.99531L7.99363 7.02813L4.61082 2.99531C4.56394 2.93906 4.49363 2.90625 4.41863 2.90625H3.17176C3.06551 2.90625 3.00769 3.02969 3.07644 3.11094L7.17801 8L3.07644 12.8891C3.06104 12.9072 3.05116 12.9293 3.04798 12.9529C3.04479 12.9764 3.04843 13.0004 3.05846 13.022C3.0685 13.0435 3.08451 13.0617 3.10459 13.0745C3.12467 13.0872 3.14798 13.0939 3.17176 13.0938H4.41863C4.49207 13.0938 4.56238 13.0609 4.61082 13.0047L7.99363 8.97188L11.3764 13.0047C11.4233 13.0609 11.4936 13.0938 11.5686 13.0938H12.8155C12.9218 13.0938 12.9796 12.9703 12.9108 12.8891L8.80926 8Z"
			fill="#9254DE"
		/>
	</svg>
);

const SaveCriterias = (props: any) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const classes = useStyles();
	return (
		<div>
			{props.currentTemplate ? (
				<div style={{ marginRight: '18px' }}>
					<CustomControlSelect
						optionsPosition="bottom"
						options={[
							{ value: 'rename', label: translate('rename', language) },
							{ value: 'delete', label: translate('delete', language) },
						]}
						svg="vertical"
						handleSelectChange={(selectedOption) => {
							props.handleSelectChange(selectedOption);
						}}
					/>
				</div>
			) : (
				<div className={classes.searchTitleRight}>
					<div className={classes.searchDeleteAllButton}>
						<UniversalButton
							buttonStyle="plain"
							handleClick={() => {
								props.removeAllCriteriasHandler();
							}}
							text={translate('searchClear', language)}
							icon={<Refresh />}
							testId="calls_btns_clear"
						/>
					</div>

					<Button
						data-testid="calls_btns_save-temp"
						className={classes.searchButton}
						startIcon={<FolderPlusSvg className={classes.searchButtonIcon} />}
						variant="contained"
						onClick={() => {
							props.handleCreateNewTemplateButtonClick();
						}}
					>
						{translate('searchSaveTemp', language)}
					</Button>
				</div>
			)}
		</div>
	);
};

export default SaveCriterias;
